// 企业认证
<template>
  <div class="task_detail_container">
    <div class="titleContainer">
      <div class="back_to_task" @click="$router.go(-1)">
        <i class="el-icon-arrow-left"></i>
        <span>返回上页</span>
      </div>
      <div class="task_title">
        <div class="title">企业认证</div>
      </div>
    </div>
    <div class="task_allInfo_container">
      <div class="item_info">
        <el-form
          ref="form"
          :hide-required-asterisk="true"
          :model="form"
          label-position="left"
          label-width="110px"
          style="margin-left:50px"
          :rules="rules">
          <el-form-item
            label="企业名称"
            prop="companyName"
            style="">
            <el-input
              type="text"
              size="small"
              v-model.trim="form.companyName"
              style="width:320px;">
            </el-input>
          </el-form-item>
          <el-form-item
            label="联系邮箱"
            prop="companyEmail">
            <el-input
              type="text"
              size="small"
              style="width:320px;"
              v-model.trim="form.companyEmail">
            </el-input>
          </el-form-item>
          <el-form-item
            label="联系电话"
            prop="companyTelephone">
            <el-input type="text"
              maxlength="11"
              size="small"
              v-model.trim="form.companyTelephone"
              style="width:320px;">
            </el-input>
          </el-form-item>
          <el-form-item
            label="营业执照"
            prop="companyLicence">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              ref="uploadFiles"
              :headers="header"
              :accept="accept"
              :on-success="fileSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="form.companyLicence" :src="form.companyLicence.url" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-button
          v-if="form.authing === 0"
          type="primary"
          style="margin-left:158px"
          size="small"
          @click="confirmCertification"
          :loading="btnLoading">
          确认认证
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import globalVar from '@/configs/globalVar'
import cookie from '@/plugins/cookie'
export default {
  data () {
    return {
      btnLoading: false,
      form: {
        companyName: '', // 企业名称
        companyEmail: '', // 联系邮箱
        companyTelephone: '', // 联系电话
        companyLicence: null // 营业执照
      },
      accept: 'image/jpeg,image/png',
      rules: {
        companyName: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        companyTelephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        companyEmail: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { pattern: /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/, message: '邮箱格式错误', trigger: 'blur' }
        ],
        companyLicence: [
          { required: true, message: '请上传营业执照', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    header () {
      const token = cookie.get(globalVar.TOKEN)
      return {
        Authorization: 'Bearer ' + token
      }
    },
    /**
     * @todo 放在环境变量中
     */
    uploadUrl () {
      return process.env.VUE_APP_API_BASE_URL + 'api/v1/media/put'
    }
  },
  created () {
    this.getCompanyStatus()
  },
  methods: {
    // 获取认证状态
    getCompanyStatus () {
      api.getUserInfo().then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          this.form = data
        }
      })
    },
    // 文件上传之前
    beforeAvatarUpload (file) {
      if (this.accept && this.$refs.uploadFiles.accept.search(file.type) === -1) {
        this.$message.warning('上传失败，文件格式不正确！')
        return false
      }
    },
    // 上传成功
    fileSuccess (res, file) {
      console.log('上传成功', res, file)
      const obj = {
        uid: file.uid,
        name: file.name,
        ...res
      }
      this.form.companyLicence = obj
    },
    // 认证
    confirmCertification () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.companyCertification({
            ...this.form
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('上传成功，请等待后台审核')
              this.getCompanyStatus()
              this.$router.go(-1)
            } else {
              this.$message.error('认证提交失败')
            }
          }).catch((error) => {
            console.log(error)
            this.$message.error('请求出错')
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.task_detail_container {
  width: 100%;
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  .titleContainer {
    height: 100px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    .back_to_task {
      font-size: 14px;
      font-weight: 500;
      color: #606266;
      cursor: pointer;
      padding: 0 30px;
    }
    .task_title {
      flex: 1;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #303133;
      }
      .time {
        font-size: 14px;
        color: #909399;
      }
    }
  }
  .task_allInfo_container {
    padding: 30px 70px 0 50px;
    ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
  }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  }
}
</style>
